<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Configuration') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'sla-list' }" >
          SLA
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{$route.params.id}}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
    <!-- back button -->
    <b-button
      variant="flat-secondary"
      class="ll-cancel"
      :to="{ name: 'sla-list' }"
    >
      <b-img
        :src="require('@/assets/images/imgs/return.png')"
        height="auto"
        class="mr-1"
      />
    </b-button>
    <!-- frist floor -->
    <b-card>
      <b-card-header>
        <b-card-title>{{ $t('General info') }}</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="4">
            <b-form-group
              class="ll-bforms1"
              :label="$t('Customer')"
              label-for="customer"
            >
              <div>{{ baseInfo.customerName }}</div>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group
              class="ll-bforms1"
              label="SLA ID"
              label-for="slaId"
            >
              <div>{{ baseInfo.slaName }}</div>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group
              class="ll-bforms1"
              :label="$t('Description')"
              label-for="des"
            >
              <div>{{ baseInfo.description }}</div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4">
            <b-form-group
              class="ll-bforms1"
              :label="$t('Effective date')"
              label-for="effectiveDate"
            >
              <div>{{ baseInfo.effectiveDate }}</div>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group
              class="ll-bforms1"
              :label="$t('Work date only')"
              label-for="workdayFlag"
            >
              <div>{{ baseInfo.workdayFlag }}</div>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group
              class="ll-bforms1"
              label="Timezone"
              label-for="timezone"
            >
              <div>{{ baseInfo.timezone }}</div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- second floor -->
    <b-card>
      <b-card-header>
        <b-card-title>{{ $t('Status list') }}</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="3">
            <b-form-group
              class="ll-bforms1"
              :label="$t('Clock start status')"
              label-for="startStatus"
            >
              <div style="margin-left:10px">{{ baseInfo.startStatus }}</div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="3">
            <b-form-group
              class="ll-bforms1"
              :label="$t('Clock stop status')"
              label-for="stopStatus"
            >
              <b-form-textarea
                v-model="textArea1"
                rows="5"
                trim
                class="ll-tArea"
              />
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group
              class="ll-bforms1"
              :label="$t('SLA due')"
              label-for="slaDue"
            >
              <div>{{ slaDue }}</div>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group
              class="ll-bforms1"
              :label="$t('Day')"
              label-for="day"
            >
              <div>{{ day }}</div>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group
              class="ll-bforms1"
              :label="$t('Hour')"
              label-for="hour"
            >
              <div>{{ hour }}</div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="3">
            <b-form-group
              class="ll-bforms1"
              :label="$t('Tracking only')"
              label-for="trackingOnly"
            >
              <b-form-textarea
                v-model="textArea2"
                rows="5"
                trim
                class="ll-tArea"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BBreadcrumb,BBreadcrumbItem, BButton, BImg,
  BRow, BCol,
  BCard, BCardHeader, BCardTitle, BCardBody,
  BFormGroup, BInputGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BButton,
    BImg,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    vSelect,
    BFormGroup,
    BInputGroup,
    BFormInput,
    flatPickr,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      baseInfo: {
        customerName: '',
        effectiveDate: '',
        endStatus: [],
        workdayFlag: '',
        hours: 0,
        orgId: '',
        slaName: '',
        startStatus: '',
        timezone: '',
        isEnabled: '',
        description: '',
        trackings: [],
      },
      workdayList: [
        { name: 'Yes', key: true },
        { name: 'No', key: false },
      ],
      timezoneList: [],
      statusList: [],
      startStatus: '',
      stopStatus: '',
      slaDue: 'By start time',
      day: '',
      hour: '',
      statusList1: [],
      textArea1: '',
      trackingOnly: '',
      textArea2: '',
      statusList2: [],
      condition: {
        page: '1',
        size: '15',
        orderBy: 'id',
        orderMole: 1,
        slaName: null,
        isEnabled: null,
      },
    }
  },
  created() {},
  mounted() {
    this.getDatails()
  },
  methods: {
    getDatails() {
      this.condition.slaName = this.$route.params.id
      this.$http.post('slaConfig/search', this.condition).then(res => {
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data
          this.baseInfo = info.content[0]
          if (info.content[0].workdayFlag === true) {
            this.baseInfo.workdayFlag = 'Yes'
          } else {
            this.baseInfo.workdayFlag = 'No'
          }
          this.textArea1 = this.baseInfo.endStatus.replace(/[\,]/g,'\n')
          this.textArea2 = this.baseInfo.trackings.replace(/[\,]/g,'\n')
          this.day = parseInt(this.baseInfo.hours/24) //取整
          this.hour = this.baseInfo.hours%24 //取余数
        }
      }).catch(error => console.log(error))
    },
  },
}
</script>
<style scoped>
.ll-bforms1{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
<style>
.ll-bforms1>label{
  width: 30%;
}
.ll-bforms1>div{
  width: 70%;
  color: #000;
  margin-bottom: 0.2857rem;
}
</style>